import {graphql, useStaticQuery} from 'gatsby';

const query = graphql`
  query {
    allContentfulJoinPage(sort: {fields: updatedAt, order: DESC}) {
      nodes {
        recruitmentSteps {
          icon {
            name
            prefix
          }
          title
          description
        }
        memberTestimonials {
          member {
            name
            position
            headshot {
              file {
                url
              }
            }
          }
          testimony {
            testimony
          }
        }
        frequentlyAskedQuestions {
          question
          answer {
            answer
          }
        }
        applicationStatus {
          url
          status
        }
      }
    }
  }
`

export default function useJoinPage() {
  const joinPageLatestNode = useStaticQuery(query).allContentfulJoinPage.nodes[0];
  return joinPageLatestNode;
}