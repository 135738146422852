import React from 'react';
import {Row, Col} from 'react-bootstrap';
import styled from 'styled-components';
import MemberCard from './MemberCard';
import leftQuote from '../assets/icons/left-quote.png';

const BottomWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 75%;
  flex-direction: row;
`

export default function HorizontalTestimonial({member, testimonial, order}) {
  const orderControl = order === 'reverse' ? 'last' : 'first';
  // TODO: fix ordering issue on small devices (from sm to md sizes, the ordering is not correct)
  return (
    <Row style={{justifyContent: 'space-evenly'}}sm={9}>
      <Col md={{span: 'auto', order: orderControl}} sm={{span: 'auto', order: 'last'}}>
        <BottomWrapper>
          <MemberCard member={member}/>
        </BottomWrapper>
      </Col>
      <Col md={8}>
        <div className="flex-container">
          <img src={leftQuote} alt="Quotation Mark" width="80px" height="50px" style={{paddingRight:"30px"}}></img>
          <p style={{fontSize: 20, marginTop:"16px"}}>{testimonial}</p>
        </div>
      </Col>
      
    </Row>
  )
}