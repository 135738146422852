import {Col, Row} from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';

const MemberImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 35px;
`

export default function MemberCard({member}) {
  return (
    <Row style={{alignItems: 'center', justifyContent: 'space-between'}}>
      <Col xs={4}>
        <MemberImage src={member.image} alt={member.name}/>
      </Col>
      <Col xs={7}>
        <h6>{member.name}</h6>
        <p style={{fontSize: '16px', marginBottom: '0px'}}>{member.position}</p>
      </Col>
    </Row>
  )
}