import React from "react";
import Fade from "react-reveal";
import {Row, Col} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Faq from "../components/Faq";
import Layout from '../components/Layout';
import SectionBlock from '../components/containers/SectionBlock';
import HorizontalTestimonial from '../components/HorizontalTestimonial';
import {ButtonA} from '../components/Button';
import Timeline from '../components/Timeline';
import GeometricBackgroundWrapper from '../components/containers/GeometricBackgroundWrapper';
import Theme from '../styles/Theme';
import useJoinPage from '../hooks/useJoinPage';

export default function Join({location}) {
  let what = "180 Degrees Consulting works effectively by recruiting from a diverse range of backgrounds" +
    " and bringing together a broad range of skills this provides. If you are a student" +
    " brimming with bright ideas and have a desire to make a real difference with your skill-set," +
    " then we encourage you to apply so that we can help you realize your potential."

  let job = "As a 180 Degrees consultant, you will work with leading OC-based" +
    " non-profits and tackle their toughest problems as an organization. You will be supported by" +
    " our network of Engagement Managers, Executive Board members, and mentors from professional management consulting" +
    " firms. A variety of training sessions, social events, and networking opportunities are also held throughout" +
    " the quarter to further complement the consultant experience. "

  const {recruitmentSteps, memberTestimonials, frequentlyAskedQuestions, applicationStatus} = useJoinPage();

  const timelineItems = recruitmentSteps.map((node) => (
    {
      title: node.title,
      content: node.description,
      icon: <FontAwesomeIcon icon={[node.icon.prefix, node.icon.name]} size={'lg'} color={'white'} style={{marginLeft: '-13px'}} fixedWidth />
    }
    ))

  return (
    <Layout location={location}>
      <GeometricBackgroundWrapper shape={'circle'} color={Theme.colors.brightLightGreen + '88'} size={'30vw'} top={'40%'} left={'8vw'}>
        <SectionBlock>
          <h2>Recruitment Timeline</h2>
          <Row className={'gy-4'} xl={2} xs={1} style={{justifyContent: 'space-between'}}>
            <Col xl={5} style={{alignSelf: 'stretch'}}>
              <Row xs={1} className={'gy-4'} style={{alignContent: 'space-evenly', height: '100%', marginTop: '0px'}}>
                <Col>
                  <h4>How we recruit</h4>
                  <p>{what}</p>
                </Col>

                <Col>
                  <h4>Life as a consultant</h4>
                  <p>{job}</p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Timeline items={timelineItems}/>
            </Col>
          </Row>
        </SectionBlock>
      </GeometricBackgroundWrapper>
      {
        memberTestimonials.map((node, i) => (
          <SectionBlock>
            <HorizontalTestimonial member={{
              name: node.member.name,
              position: node.member.position,
              image: node.member.headshot.file.url
            }}
                                   testimonial={node.testimony.testimony}
                                   order={i % 2 === 0 ? '': 'reverse'}
                                   />
          </SectionBlock>
        ))
      }

      <GeometricBackgroundWrapper
        shape={'circle'}
        size={'800px'}
        left={'60vw'}
        top={'0px'}
        color={Theme.colors.lightBlue025}
      >
        <SectionBlock>
          <div className={'text-center'}>
            <h1 className={'text-center'}>
              Ready to join?
            </h1>
            <br/><br/>
            <p>
              {applicationStatus.status}
            </p>
            <br/>
            <ButtonA href={applicationStatus.url} large>Apply Now</ButtonA>
          </div>
        </SectionBlock>
      </GeometricBackgroundWrapper>

      <SectionBlock>
        <div>
          <h2>Frequently Asked Questions</h2>
          <Row className={'gy-4'}>
            <Fade>
              {
                frequentlyAskedQuestions.map((node) => (
                  <Col>
                    <Faq question={node.question} answer={node.answer.answer}/>
                  </Col>
                ))
              }
            </Fade>
          </Row>
        </div>
      </SectionBlock>
    </Layout>
  );
}
