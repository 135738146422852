import styled from 'styled-components';

const HoverCard = styled.div`
  background-color: #f9f9f9f9;
  transition: box-shadow  100ms ease-out;
  
  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
`

export default HoverCard;
